









import {Component, Vue} from "vue-property-decorator";
import {Api} from "@/backendClient/Api";
import authService from "@/services/AuthService"

@Component
export default class MySpace extends Vue {
  private client : Api<never> | undefined

  private nickname = ""
  private imageUrl = ""

  public mounted(): void {

    let jwt = authService.getJwt()
    if (jwt == null) {
      this.gotoLogin()
    }

    console.log(process.env.VUE_APP_BACKEND_URL)
    console.log(process.env)
    this.client = new Api({baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`, headers: {Authorization: "Bearer " + jwt}})

    this.client.auth.authGetMyInfo({}).then(r => {
      this.nickname = r.data.nickname || ""
      this.imageUrl = r.data.image_url || ""
    }, () => {
      this.gotoLogin()
    })
  }

  private gotoLogin() {
    this.$router.push({ path: '/login'})
  }

  private doLogout() {
    authService.clearJwt()
    this.gotoLogin()
  }
}
